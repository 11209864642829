import alertCircle from '@/assets/icons/alertCircle.svg'
import keyhole from '@/assets/icons/keyhole.svg'
import keyholeFill from '@/assets/icons/keyholeFill.svg'
import keyholeWhite from '@/assets/icons/keyholeWhite.svg'
import zap from '@/assets/icons/zap.svg'
import zapFill from '@/assets/icons/zapFill.svg'
import star from '@/assets/icons/star.svg'
import arrowRight from '@/assets/icons/arrowRight.svg'
import download from '@/assets/icons/download.svg'
import list from '@/assets/icons/list.svg'
import grid from '@/assets/icons/grid.svg'
import info from '@/assets/icons/info.svg'
import houseRed from '@/assets/icons/houseRed.svg'
import fire from '@/assets/icons/fire.svg'
import electricity from '@/assets/icons/electricity.svg'
import forbidden from '@/assets/icons/forbidden.svg'
import questionmark from '@/assets/icons/questionmark.svg'
import houseYellow from '@/assets/icons/houseYellow.svg'
import houseGray from '@/assets/icons/houseGray.svg'
import houseQuestionmark from '@/assets/icons/houseQuestionmark.svg'
import sun from '@/assets/icons/sun.svg'
import acoustic from '@/assets/icons/acoustic.svg'
import ventilation from '@/assets/icons/ventilation.svg'
import soundRatio from '@/assets/icons/soundRatio.svg'
import qualitySocial from '@/assets/icons/quality-social.svg'
import qualityEconomic from '@/assets/icons/quality-economic.svg'
import qualityEnvironmental from '@/assets/icons/quality-environmental.svg'
import close from '@/assets/icons/close.svg'
import burger from '@/assets/icons/burger.svg'
import homeLogo from '@/assets/icons/homeLogo.svg'
import chevron from '@/assets/icons/chevron.svg'
import plus from '@/assets/icons/plus.svg'
import repair from '@/assets/icons/repair.svg'
import replace from '@/assets/icons/replace.svg'
import adjust from '@/assets/icons/adjust.svg'
import emptyEleftersyn from '@/assets/icons/emptyEleftersyn.svg'
import emptyEnergimærke from '@/assets/icons/emptyEnergimærke.svg'
import emptyChart from '@/assets/icons/emptyChart.svg'

export const iconMap = {
  acoustic: acoustic,
  adjust: adjust,
  alert: alertCircle,
  arrowRight: arrowRight,
  burger: burger,
  chevron: chevron,
  close: close,
  download: download,
  electricity: electricity,
  emptyEleftersyn: emptyEleftersyn,
  emptyEnergimærke: emptyEnergimærke,
  emptyChart: emptyChart,
  fire: fire,
  forbidden: forbidden,
  grid: grid,
  homeLogo: homeLogo,
  houseGray: houseGray,
  houseQuestionmark: houseQuestionmark,
  houseRed: houseRed,
  houseYellow: houseYellow,
  info: info,
  keyhole: keyhole,
  keyholeFill: keyholeFill,
  keyholeWhite: keyholeWhite,
  list: list,
  plus: plus,
  qualityEconomic: qualityEconomic,
  qualityEnvironmental: qualityEnvironmental,
  qualitySocial: qualitySocial,
  questionmark: questionmark,
  repair: repair,
  replace: replace,
  soundRatio: soundRatio,
  star: star,
  sun: sun,
  ventilation: ventilation,
  zap: zap,
  zapFill: zapFill,
}
